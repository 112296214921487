<template>
  <div>

   <LanguageSelector />

    <h5 class="my-4 mx-5 text-center">{{ $t('welcome') }}</h5>
    <form
      @submit.prevent="login"
      class="ui form w-100 text-center align-items-center justify-content-center auth-form"
      novalidate>

      <TextField
        :model-value="form.email"
        @update:model-value="form.email=$event"
        type="email"
        :label="$t('form.email')"
          />

      <TextField
        style="margin-top: 20px; margin-bottom: 15px"
        :model-value="form.password"
        @update:model-value="form.password=$event"
        type="password"
        :label="$t('form.password')"
      />

      <p class="m-0">
        <router-link
          style="font-size:inherit !important"
          to="/auth/forgotPassword"
        >{{ $t("forgotPassword") }}</router-link>
      </p>
    </form>

    <Button
      class="mt-3"
      color="new"
      :text="$t('buttons.signin')"
      @clicked="login"
    />

    <div style="height: 15px; margin-top: 10px" v-if="(v$.form.email.$error || v$.form.password.$error || invalidCredentials)">
      <span class="text-danger"  v-if="v$.form.email.$error">
        {{v$.form.email.$errors[0].$message }}
      </span>
      <span v-else-if="v$.form.password.$error" class="text-danger">{{v$.form.password.$errors[0].$message }}</span>
      <span style="margin-top: 20px" v-else-if="invalidCredentials" class="text-danger">{{$t('errors.invalidCredentials') }}</span>
    </div>
  </div>
</template>

<script>
import Button from '@/components/form/Button'
import TextField from '@/components/form/TextField'
// validations
import { useVuelidate } from '@vuelidate/core'
import { required, email, helpers } from '@vuelidate/validators/dist/raw.esm'
import { me, updateLanguage } from '@/api/user'
import { mapActions } from 'vuex'
import i18n from '@/plugins/i18n'
import LanguageSelector from '@/components/languageSelector/LanguageSelector'

export default {
  name: 'SignIn',
  components: {
    TextField,
    Button,
    LanguageSelector
  },
  setup: () => ({ v$: useVuelidate() }),
  data () {
    return {
      isLoading: false,
      invalidCredentials: false,
      form: {
        email: '',
        password: '',
        role: 1
      }
    }
  },
  validations () {
    return {
      form: {
        email: {
          required: helpers.withMessage(this.$t('validations.email.required'), required),
          email: helpers.withMessage(this.$t('validations.email.invalid'), email)
        },
        password: {
          required: helpers.withMessage(this.$t('validations.password.required'), required)
        }
      }
    }
  },
  methods: {
    ...mapActions('user', ['setMeAdminInfo']),
    login () {
      this.v$.form.$touch()
      this.invalidCredentials = false
      const formValid = !(this.v$.form.$error)

      if (formValid) {
        this.$store.dispatch('oauth/logIn', this.form)
          .then((resp) => {
            // this.$router.replace({ path: '/home' })
            // console.log('LOGIN SUCCESSFUL ---> RESP', resp)
            // console.log(me())
            return me()
          }).then((resp) => {
            const data = {
              language: i18n.global.locale
            }
            updateLanguage(data)
            return resp
          }).then((resp) => {
            console.log('resp data -> ', resp.data)
            this.setMeAdminInfo(resp.data)
            this.$router.replace({ path: '/home' })
          })
          .catch(() => {
            this.invalidCredentials = true
          })
      }
    }
  }
}
</script>

<style scoped>
  h5{
    font-size: 1.65rem;
  }
</style>
