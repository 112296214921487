<template>
  <div :key='componentKey'>
    <select
      v-model='selectedLanguage'
      @change='changeLanguage'
      class='language-selector'
    >
      <option value='es'>Spanish</option>
      <option value='en'>English</option>
    </select>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  computed: {
    ...mapState('language', ['selectedLanguage'])
  },
  methods: {
    ...mapActions('language', ['setLanguage']),
    changeLanguage (event) {
      // console.log('changeLanguage method called')
      // console.log(event.target.value) // Use event.target.value to get the selected language
      this.setLanguage(event.target.value)
    }
  },
  data () {
    return {
      componentKey: Date.now() // Generate a unique key for each component instance
    }
  }
}
</script>

<style scoped>
/* Add your component styles here */
.language-selector {
  position: absolute;
  top: 1rem;
  right: 1rem;
  padding: 0.3rem;
  font-size: 1rem;
  border: 1px solid #8f989b;
  border-radius: 0.5rem;
}
</style>
